// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// import i18n from '@/lang'

// 启用路由
Vue.use(Router);

export const constantRoutes = [
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'entrance',
        component: () => import('@/views/Common/Entrance'),
        meta: {
            title: "服務列表",
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home/Home.vue'),
        props: true,
        meta: {
            title: "首页",
        }
    },
    {
        path: '/appointment',
        name: 'appointment',
        component: () => import('@/views/Home/Appointment.vue'),
        meta: {
            title: "预约时间",
        },
    },
    {
        path: '/bookingRecord',
        name: 'bookingRecord',
        component: () => import('@/views/BookingRecord/RecordList.vue'),
        meta: {
            title: "预约记录",
        },
    },
    {
        path: '/recordDetail',
        name: 'recordDetail',
        component: () => import('@/views/BookingRecord/RecordDetail.vue'),
        meta: {
            title: "预约详情",
        },
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/views/UserCenter/UserInfo.vue'),
        meta: {
            title: "个人中心",
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Common/Login'),
        meta: {
            title: "登录",
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Common/Register'),
        meta: {
            title: "注册",
        }
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/Home/Agreement'),
        meta: {
            title: "用户协议",
        }
    },
    {
        path: '/emptyPage',
        name: 'emptyPage',
        component: () => import('@/views/Common/emptyPage'),
        meta: {}
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/Common/404'),
        meta: {}
    }];

export const asyncRouterMap = []

// 导出路由
const router = new Router({
    mode: 'history',
    routes: constantRoutes
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};


// 添加 afterEach 钩子
router.afterEach((to) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'pageview',
        'pagePath': to.fullPath,
        'pageTitle': to.meta.title || ''
    });
});

export default router;