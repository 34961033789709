import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission.js'
import * as custom from './utils/util'
import Bus from '@/utils/bus'
import i18n from './lang'
import Vant from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css';
import serviceParamMixin from '@/mixins/serviceParamMixin'

Vue.mixin(serviceParamMixin)
Vue.use(Vant);
Toast.setDefaultOptions({ duration: 3000 });

Vue.prototype.$bus = Bus

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')



