import { getStore } from '@/utils/util'

export default {
  methods: {
    /**
     * 带服务参数的路由跳转
     * @param {Object|string} location - 路由位置对象或路径
     * @returns {Promise} 路由跳转的 Promise
     */
    routerPushWithService(location) {
      const serviceParam = getStore('serviceParam')
      const serviceValue = getStore('serviceValue')
      const typeCode = getStore('typeCode')

      // 转换路由配置对象
      const routeConfig = typeof location === 'string'
        ? { path: location }
        : { ...location }

      // 添加查询参数
      if (serviceParam && serviceValue) {
        routeConfig.query = {
          ...(routeConfig.query || {}),
          [serviceParam]: serviceValue
        }
      }

      if (typeCode) {
        routeConfig.query = {
          ...(routeConfig.query || {}),
          typeCode
        }
      }

      // 确保使用完整的路由配置
      const fullConfig = {
        path: routeConfig.path,
        query: routeConfig.query || {},
        params: routeConfig.params || {}
      }

      console.log('Router config:', fullConfig)

      // 返回 Promise 以便处理导航结果
      return this.$router.push(fullConfig).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation failed:', err)
          throw err
        }
      })
    }
  }
}