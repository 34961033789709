import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getStore, removeStore, setStore } from './utils/util'

NProgress.configure({ showSpinner: false })

const whiteList = ['/index', '/login', '/register', '/agreement']

const SERVICE_CONFIG = {
  SERVICE: 'service',
  SERVICE_CODE: 'serviceCode'
}

// 获取当前服务参数
const getServiceQuery = () => {
  const serviceParam = getStore('serviceParam')
  const serviceValue = getStore('serviceValue')
  const typeCode = getStore('typeCode')

  if (serviceParam && serviceValue) {
    return {
      [serviceParam]: serviceValue,
      typeCode
    }
  }
  return {}
}

// 添加服务参数到路由配置
const addServiceParams = (routeConfig) => {
  const serviceQuery = getServiceQuery()

  if (typeof routeConfig === 'string') {
    return {
      path: routeConfig,
      query: serviceQuery
    }
  }

  return {
    ...routeConfig,
    query: {
      ...serviceQuery,
      ...(routeConfig.query || {})
    }
  }
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  // 处理服务参数
  const { service, serviceCode, typeCode } = to.query

  // 如果 URL 中没有服务参数，但存储中有 清除缓存
  if (!service && !serviceCode) {
    const storedService = getStore('serviceParam')
    const storedValue = getStore('serviceValue')

    storedService && removeStore('serviceParam')
    storedValue && removeStore('serviceValue')
  }

  if (!typeCode) {
    const storedTypeCode = getStore('typeCode')
    storedTypeCode && removeStore('typeCode')
  }

  // 如果 URL 中有服务参数，存储它
  if (service) {
    setStore('serviceParam', SERVICE_CONFIG.SERVICE)
    setStore('serviceValue', service)
  } else if (serviceCode) {
    setStore('serviceParam', SERVICE_CONFIG.SERVICE_CODE)
    setStore('serviceValue', serviceCode)
  }

  if (typeCode) {
    setStore('typeCode', typeCode)
  }

  const token = getStore('token')
  // 已登录用户处理
  if (token) {
    if (to.path === '/login' || to.path === '/index') {
      next(addServiceParams('/home')) // 添加服务参数到重定向
      return
    }
    next()
    return
  }

  // 白名单处理
  if (whiteList.includes(to.path)) {
    next()
    return
  }

  if (service || serviceCode) {
    next(addServiceParams('/login'))
    return
  }
  next('/index')
})

router.afterEach(() => {
  NProgress.done()
})